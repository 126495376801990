import React from 'react';
import './App.css';

const App: React.FC = () => {
  function navigateToResume(e: React.MouseEvent) {
    e.preventDefault();
    window.location.href = "/resume.pdf";
  }
  return (
    <div className="App">
      <header className="App-header">
        <div id="MainSection">
          <h1>John B. Mains</h1>
          <h2>Roboticist</h2>
          <a id="ResumeButton" href="#" onClick={navigateToResume}><h3>Resume</h3></a>
        </div>
      </header>
    </div>
  );
}

export default App;
